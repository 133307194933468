import React from "react"
import styled from "styled-components"
import { ImageContainer } from "./CrmImageContainer"

const InsightsSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: space--between;
  background-color: #171f2a;
  padding: 61px 60px 26px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`

const InsightsContainer = styled.div`
  width: 90%;
//   max-width: 1115px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const InsightsContent = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`

const InsightsInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 61%;
  margin-left: 0px;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const InsightsText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`

const InsightsTitle = styled.h2`
  font-feature-settings: "liga" off;
  background: linear-gradient(
    163deg,
    #a8b7d3 9.11%,
    #b5c6e2 34.93%,
    #d3e0ef 57.41%,
    #e5effa 93.73%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 800 40px Orbitron, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const InsightsDescription = styled.p`
  color: var(--night-text-03, #b6c1d5);
  font-family: IBM Plex Sans, sans-serif;
  margin-top: 24px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const InsightsImage = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 39%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const InsightsImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 400;
  padding: 14px 44px 67px 0;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`

const InsightsImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  aspect-ratio: 0.93;
  padding: 80px 21px 0 0;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`

const InsightsImg = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const InsightsEmoji = styled.div`
  position: relative;
  color: var(--night-text-01, #fff);
  letter-spacing: 0.2px;
  align-self: end;
  margin: 157px 49px 0 0;
  font: 32px Inter, sans-serif;

  @media (max-width: 991px) {
    margin: 40px 10px 0 0;
  }
`

const StyledInsightsSuggestion1 = styled.div`
-webkit-backdrop-filter: blur(32px) brightness(100%);
    align-items: flex-end;
    backdrop-filter: blur(32px) brightness(100%);
    background-color: #25303ecc;
    border: 1px solid;
    border-color: var(--accentteal-00c7b7);
    border-radius: 8px;
    display: flex;
    gap: 9.63px;
    left: 0;
    padding: 12px;
    position: absolute;
    top: 277px;
    width: 342px;
`

// Styled component for the second InsightsSuggestion container
const StyledInsightsSuggestion2 = styled.div`
  -webkit-backdrop-filter: blur(32px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(32px) brightness(100%);
  background-color: #00000080;
  border: 1px solid;
  border-color: #ffffff66;
  border-radius: 8px;
  display: flex;
  gap: 9.63px;
  padding: 8px;
  width: 307px;

  // Adjust position styles if needed
`


const InsightsSuggestion = styled.div`
  position: relative;
  border-radius: 8px;
  backdrop-filter: blur(15.999999046325684px);
  border: 1px solid rgba(0, 199, 183, 1);
  background-color: rgba(37, 48, 62, 0.8);
  margin-top: 14px;
  justify-content: center;
  color: var(--night-text-02, #dfe3ea);
  letter-spacing: -0.33px;
  padding: 12px 12px 12px 0;
  font: 13px/18px Roboto, sans-serif;
`

const ClientInsights = () => {
  return (
    <InsightsSection>
      <InsightsContainer>
        <InsightsContent>
          <InsightsInfo>
            <InsightsText>
              <InsightsTitle>Get Client Insights</InsightsTitle>
              <InsightsDescription>
                Sentiment ... Effortlessly keep track of client progress for
                personalized care and support. With real-time insights, stay
                ahead of client needs and ensure optimal progress towards their
                wellness goals.
              </InsightsDescription>
              <InsightsDescription>
                Progress ... Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo.
              </InsightsDescription>
            </InsightsText>
          </InsightsInfo>
          <ImageContainer/>
        </InsightsContent>
      </InsightsContainer>
    </InsightsSection>
  )
}

export default ClientInsights
