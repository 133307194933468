import React from "react"
import styled from "styled-components"
import sadImage from "../images/sadImage.png"
function HeroSection() {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroImageWrapper>
          <StyledImage className="image" alt="Image" src={sadImage} />
        </HeroImageWrapper>
        <HeroTextWrapper>
          <HeroTextContent>
            <HeroTitle>Stay on top of your game</HeroTitle>
            <HeroSubtitle>
              Designed to enhance empathy and coaching skills, our innovative
              platform leverages AI to provide personalized insights, enabling
              coaches to connect with clients on a deeper level and achieve
              unparalleled results.
            </HeroSubtitle>
            <HeroDescription>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </HeroDescription>
          </HeroTextContent>
        </HeroTextWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  height:70vh;
  padding-top:70px;
  width:90%;
`
const StyledImage = styled.img`
  width: 80%;
  height: 90%;
`

const HeroContent = styled.div`
  width: 100%;
  display:flex;


  @media (max-width: 991px) {
    max-width: 100%;
  }
`


const HeroImageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-right: 8px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`

const HeroImage = styled.img`
  aspect-ratio: 1.1;
  object-fit: cover;
  width: 100%;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const HeroTextWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  width: 56%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const HeroTextContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: auto 0;
  padding: 0 20px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`

const HeroTitle = styled.h1`
  background: linear-gradient(
    163deg,
    #a8b7d3 9.11%,
    #b5c6e2 34.93%,
    #d3e0ef 57.41%,
    #e5effa 93.73%
  );
  background-clip: text;
  font: 800 40px Orbitron, sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const HeroSubtitle = styled.p`
  color: var(--night-text-03, #b6c1d5);
  font-family: "IBM Plex Sans", sans-serif;
  margin-top: 16px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const HeroDescription = styled.p`
  color: var(--night-text-03, #b6c1d5);
  font-family: "IBM Plex Sans", sans-serif;
  margin-top: 16px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

export default HeroSection
