import React from "react"
import styled from "styled-components"
import sadImages from "../images/sadImages.png"
import { StaticImage } from "gatsby-plugin-image"
const StyledImage = styled.div`
  height: 453px;
  position: relative;
  width: 391px;

  .overlap {
    position: relative;
    width: 391px;
    height: 453px;
    margin-left:50px;
  }

  .frame {
    position: absolute;
    top: 0;
    left: 13px;
    width: 350px;
    height: 372px;
  }

  .overlap-group {
    position: relative;
    width: 466px;
    height: 475px;
    top: -131px;
    left: -88px;
  }

  .ellipse {
    position: absolute;
    top: 172px;
    left: 100px;
    width: 291px;
    height: 290px;
    border-radius: 145.52px/145.15px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(0, 199, 183) 39.58%,
      rgb(0, 150.18, 138.11) 72.4%,
      rgb(0, 91.37, 84.03) 100%
    );
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 50vh;
    z-index: 100; /* Set a higher z-index */
  }

  .bubble,
  .div {
    position: absolute;
    border: 1px solid;
    border-radius: 8px;
    backdrop-filter: blur(32px) brightness(100%);
    padding: 12px;
    gap: 9.63px;
    align-items: flex-end;
  }

  .bubble {
    background-color: #25303ecc;
    top: 320px;
    width: 342px;
    left: 0;
    border-color: rgb(5, 180, 167);
  }

  .div {
    background-color: #00000080;
    border-color: #ffffff66;
    top: 260px;
    width: 307px;
    left: 132px;
  }

  .suggested-action,
  .client-sentiment {
    flex: 1;
    font-weight: 400;
    letter-spacing: -0.04px;
  }

  .suggested-action {
    font-family: "Roboto-Bold", Helvetica;
    font-size: 12.8px;
    line-height: 17.7px;
    color: var(--nighttext-02);
    margin-top: -1px;
  }

  .client-sentiment {
    font-family: "Inter-Bold", Helvetica;
    font-size: 12px;
    line-height: 16.1px;
    color: var(--nighttext-01);
    margin-top: -1px;
  }

  .text-wrapper,
  .text-wrapper-2,
  .text-wrapper-3 {
    font-weight: 700;
    letter-spacing: 0.02px;
    color: white;
  }

  .text-wrapper-4 {
    color: white;
    font-family: "Inter-Regular", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: normal;
    position: absolute;
    top: -12px;
    left: 129px;
    white-space: nowrap;
  }

`

export const ImageContainer = () => {
  return (
    <StyledImage>
      <div className="overlap">
          <img className="image" alt="Image" src={sadImages} />
        <div className="bubble">
          <p className="suggested-action">
            <span className="text-wrapper">Suggested Action:</span>
            <span className="text-wrapper-3" style={{ display: "block" }}>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </p>
        </div>
        <div className="div">
          <p className="client-sentiment">
            <span className="text-wrapper-2">Client Sentiment:{"  "}</span>

            <span className="text-wrapper-3" style={{ display: "block" }}>
              Client expresses frustration and concern about slow progress due
              to travel and fatigue.
            </span>
          </p>
          <div className="text-wrapper-4">😖</div>
        </div>
      </div>
    </StyledImage>
  )
}
