import * as React from "react";
import styled from "styled-components";

function CrmTopContainer() {
  return (
    <Container>
      <Content>
        <LeftColumn>
          <Heading>
            <Title>Disrupting CRM Experience with Generative AI</Title>
            <Subtitle>
              Make your Coach a rockstar and ignite Customer loyalty
            </Subtitle>
            <Description>
              Fit Hub CRM empowers nutrition, fitness and other wellness professionals by
              continually comprehending their clients' progress and needs, suggesting next
              steps in their health journeys, providing actionable coach feedback, and 
              prompting coaches with appropriate responses and actions to streamline 
              time spent with their clients.
            </Description>
          </Heading>
        </LeftColumn>
        <RightColumn>
          <Cards>
            <Card>
              <CardHeader>
                <CardIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/7f6976c6038764cf6107aa45d6cd2de608aef47e0d00e2e9f4a672ee7923d3cc?apiKey=638f3c8807814d56821fb498160cef2a&" alt="Fit Hub CRM logo" />
                <CardTitle>Fit Hub CRM Tools</CardTitle>
              </CardHeader>
              <CardDescription>
               Are you seeking to expand your business to serve hundreds and thousands of clients? 
               Our tooling allows you to manage all of your client's data and profile, communicate with 
               your clients, create workout assignments such as stretches, yoga, meditation and fitness and assign 
               them to your clients, and get actionable AI based insights about clients - All in one place!   
              </CardDescription>
            </Card>
            <Card2>
              <CardHeader>
                <CardIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/611b10e18be210be6852347c45843d0959971ab36d40873d152e97c73fcb8663?apiKey=638f3c8807814d56821fb498160cef2a&" alt="Fit Hub CRM APIs icon" />
                <CardTitle>Fit Hub CRM APIs</CardTitle>
              </CardHeader>
              <CardDescription>
                Have your own CRM tool, and looking to enhance it with AI? We got you covered.
                You can integrate with our AI-powered insights and suggestions in less than a week. Our plug and play solutions
                require fewer than 3 API calls.
              </CardDescription>
            </Card2>
          </Cards>
        </RightColumn>
      </Content>
    </Container>
  );
}

const Container =styled.section`
  justify-content: center;
  background-color: #0a171d;
  padding: 40px 80px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 58%;
  margin-left: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 500;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3), 0 8px 12px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    163deg,
    #a8b7d3 9.11%,
    #b5c6e2 34.93%,
    #d3e0ef 57.41%,
    #e5effa 93.73%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 64px/78px Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 54px;
  }
`;

const Subtitle = styled.h2`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3), 0 8px 12px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    163deg,
    #a8b7d3 9.11%,
    #b5c6e2 34.93%,
    #d3e0ef 57.41%,
    #e5effa 93.73%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 24px;
  font: 32px/40px IBM Plex Sans, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Description = styled.p`
  color: #b6c1d5;
  font-feature-settings: "liga" off;
  letter-spacing: 0.4px;
  margin-top: 24px;
  font: 400 20px/32px IBM Plex Sans, -apple-system, Roboto, Helvetica,
    sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%; /* Adjusted for mobile */
  margin-left: 0; /* Adjusted for mobile */

  @media (min-width: 992px) {
    width: 42%; /* Reset width for larger screens */
    margin-left: 20px; /* Reset margin for larger screens */
  }
`

const Cards = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #fff;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px; /* Adjusted margin for mobile */
  }
`


const Card = styled.div`
  border-radius: 9.384px;
  background: ${(props) => props.background};
  box-shadow: 0 9.384px 9.384px -2.933px rgba(0, 0, 0, 0.06),
    0 2.148px 2.148px -1.955px rgba(0, 0, 0, 0.16),
    0 0.565px 0.565px -0.978px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  padding: 20px 24px;

  &:not(:first-child) {
    margin-top: 24px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;


const Card2 = styled.article`
  border-radius: 9.384px;
  background: linear-gradient(135deg, #43cbff 0%, #6f1ab2 100%);
  box-shadow: 0px 9.384px 9.384px -2.933px rgba(0, 0, 0, 0.06),
    0px 2.148px 2.148px -1.955px rgba(0, 0, 0, 0.16),
    0px 0.565px 0.565px -0.978px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  padding: 20px 24px;

  &:not(:first-child) {
    margin-top: 24px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`


Card.defaultProps = {
  background: "linear-gradient(135deg, #03b0a2 42.94%, #0172a3 100%)",
};

const CardHeader = styled.div`
  align-self: start;
  display: flex;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const CardIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 79px;
`;

const CardTitle = styled.h3`
  font-family: IBM Plex Sans, sans-serif;
  flex-grow: 1;
  margin: auto 0;
  color:white;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const CardDescription = styled.p`
color: white;
  letter-spacing: 0.36px;
  margin-top: 12px;
  font: 400 18px/32px IBM Plex Sans, -apple-system, Roboto, Helvetica,
    sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default CrmTopContainer;