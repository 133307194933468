
import React from "react"
import CrmTopContainer from "../components/CrmTopContainer"
import ClientInsights from "../components/CrmClientInsight"
import BookADemo from "../components/BookADemo"
import HeroSection from "../components/CrmCoachingPlatformSection"


const CRM = () => {
  return (
    <>
      <CrmTopContainer />
      <BookADemo />
    </>
  )
}

export default CRM
